/* eslint-disable react/prop-types,no-await-in-loop,no-param-reassign */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import CSVReader from 'react-csv-reader';
import { Check, Close } from '@material-ui/icons';
// import moment from 'moment';
import { alert, business, loyalties } from '../../../state';
import { TransactionTable } from '../../custom';
import CustomInputField from '../../custom/CustomInputField';
import DownloadIcon from '../../../images/download.png';
import ConfirmationDialog from '../../custom/ConfirmDialog';

const listOfMonths = [
  { name: 'January', value: 1 },
  { name: 'February', value: 2 },
  { name: 'March', value: 3 },
  { name: 'April', value: 4 },
  { name: 'May', value: 5 },
  { name: 'June', value: 6 },
  { name: 'July', value: 7 },
  { name: 'August', value: 8 },
  { name: 'September', value: 9 },
  { name: 'October', value: 10 },
  { name: 'November', value: 11 },
  { name: 'December', value: 12 },
];
const colsTitles = [
  'Loyalty #',
  'First Name',
  'Last Name',
  'Birthday Month',
  'Points to Add',
  '',
];

const rowKeys = [
  'code',
  'firstName',
  'lastName',
  'birthMonth',
  'point',
  'sent',
];

const AddPoints = () => {
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  // const data = useSelector(card.selectors.selectPhysicalGiftCardList);
  const [currency, setCurrency] = useState('USD');
  const [businessData, setBusinessData] = React.useState({});

  // const currency = businessData.currency || 'USD';

  const currencyFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    }).format(value);
  const refForm = useRef();
  const refLink = useRef();
  const csvRef = useRef();
  const [, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('');
  const submit = async () => {
    await setIsLoading(true);
    const updatedArray = data;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < updatedArray.length; i++) {
      const row = updatedArray[i];
      updatedArray[i].sent = 'loading';
      setData(updatedArray);
      setCurrentIndex(i);
      try {
        const resp = await dispatch(
          loyalties.actions.updateLoyalty({
            businessId: businessData.id,
            ...row,
            birthMonth: listOfMonths.find(
              (m) => m.name?.toLowerCase() === row.birthMonth?.toLowerCase()
            )?.value,
          })
        );
        if (resp.payload) {
          updatedArray[i].sent = true;
          setData(updatedArray);
          openAlert({
            message: `Loyalty # ${resp.payload?.code} is updated successfully`,
            severity: 'success',
          });
        } else if (resp.error) {
          updatedArray[i].sent = 'failed';
          setData(updatedArray);
          openAlert({
            message: `Failed to import loyalty # ${updatedArray[i].code}!\nError message: ${resp.error?.message}`,
            severity: 'error',
          });
          break;
        }
      } catch (error) {
        console.log(error);
        break;
      }
      if (i === updatedArray.length) {
        openAlert({
          message: `All Loyalties were imported successfully`,
          severity: 'success',
        });
      }
    }
    await setIsLoading(false);
  };

  const updateLoyalty = async () => {
    submit();
  };

  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => rowKeys[colsTitles.indexOf(header)],
  };

  const downloadCSV = () => {
    fetch('../../../Update Loyalty points and birthday month.csv').then(
      (response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'Sample file for importing loyalties.csv';
          alink.click();
        });
      }
    );
  };

  const handleCsvUpload = (_data) => {
    _data.forEach((row, i) => {
      _data[i].email = _data[i].email?.replace(/\s/g, '');
      _data[i].point = _data[i].point || 0;
    });
    let error = '';
    // eslint-disable-next-line array-callback-return
    _data.map((r) => {
      if (r.point) {
        if (r.point <= 0) {
          error = `Points to Add of Loyalty # ${r.code}  must be positive`;
        }
      }

      if (error) {
        setErrorMessage(error);
        csvRef.current.value = '';
      }
    });
    if (error) {
      return;
    }
    setData(_data);
  };

  return (
    <>
      <Box py={1}>
        <Grid container spacing={2} style={{ flexGrow: 1 }}>
          <Grid item md={12} xs={12}>
            <Formik
              validationSchema={Yup.object({
                businessId: Yup.string().required('Required'),
              })}
              initialValues={{
                businessId: 'a6a900a9-5661-4721-a035-59469bd3027d',
                merchantName: '',
                posSystem: '',
              }}
              onSubmit={updateLoyalty}
            >
              {(props) => {
                const { handleSubmit } = props;
                refForm.current = props;
                const getBusiness = async () => {
                  const res = await dispatch(
                    business.actions.getBusiness(props.values.businessId)
                  );
                  if (res.error) {
                    openAlert({
                      message: res.error?.message || 'Merchant is not found',
                      severity: 'error',
                    });
                    setBusinessData({});
                  } else {
                    setBusinessData(res.payload || {});
                    setCurrency(res.payload?.currency || 'USD');
                  }
                };
                return (
                  <Form>
                    <Grid container md={10} spacing={2}>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Enter merchant ID"
                          name="businessId"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Merchant Name"
                          name="merchantName"
                          value={businessData.name}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Pos System"
                          name="posSystem"
                          variant="outlined"
                          value={businessData.partner}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={getBusiness}
                          size="large"
                          disabled={!props.values.businessId}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg="auto">
                          <Button
                            variant="contained"
                            color="primary"
                            component="label"
                            disabled={!businessData.id}
                          >
                            Import from CSV File
                            <CSVReader
                              inputId="CSVReader"
                              inputStyle={{ display: 'none' }}
                              onFileLoaded={handleCsvUpload}
                              parserOptions={parseOptions}
                              ref={csvRef}
                            />
                          </Button>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title="Download Sample CSV File"
                            placement="top"
                            arrow
                          >
                            <Button
                              onClick={downloadCSV}
                              style={{ marginLeft: 10 }}
                            >
                              <img
                                src={DownloadIcon}
                                alt="Download Sample CSV File"
                                style={{ width: 30, cursor: 'pointer' }}
                              />
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item style={{ marginLeft: 'auto' }}>
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            size="large"
                            style={{
                              backgroundColor: '#153a09',
                              color: '#ffffff',
                            }}
                            disabled={!data.length}
                          >
                            Add points to existing customers
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid item md={12} xs={12}>
          <CSVLink
            data={data}
            filename="Update Loyalty points and birthday month.csv"
            className="hidden"
            ref={refLink}
            target="_blank"
            headers={headers}
          />
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data.slice(
              (params.page - 1) * params.pageSize,
              params.page * params.pageSize
            )}
            mods={{
              sent: (val) => {
                switch (val) {
                  case true:
                    return (
                      <Check
                        color="secondary"
                        size={14}
                        style={{ color: 'green' }}
                      />
                    );
                  case 'loading':
                    return <CircularProgress color="primary" size={14} />;
                  case 'failed':
                    return <Close color="error" size={14} />;
                  default:
                    return '';
                }
              },
              amount: (val) => currencyFormat(val.toFixed(2)),
              // isExternal: (val) =>
              //   Number(val) === 1 ? <CheckBox /> : <CheckBoxOutlineBlank />,
            }}
            pagination={{
              total: data.length,
              pageSize: params.pageSize,
              page: params.page,
              handleChangePage: (event, page) =>
                setParams({ ...params, page: page + 1 }),
              handleChangeRowsPerPage: (event) =>
                setParams({ ...params, pageSize: event.target.value, page: 1 }),
            }}
            // isLoading={isLoading}
            key={currentIndex}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Grid>
      </Box>

      <ConfirmationDialog
        open={!!errorMessage}
        setOpen={setErrorMessage}
        title="Invalid Data"
        isConfirmation={false}
        content={errorMessage}
        actionOk={() => {
          setErrorMessage('');
        }}
      />
    </>
  );
};

export default AddPoints;
