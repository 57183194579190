export const PartnerEnum = {
  BHN: 'BHN',
  Square: 'Square',
  Clover: 'Clover',
  Shopify: 'Shopify',
};

export const FranchiseType = {
  GiftCard: 0,
  Loyalty: 1,
  Coupon: 2,
  Membership: 3,
};

export const LANGUAGES = [
  { lang: 'en-US', name: 'English' },
  { lang: 'fr-CA', name: 'Francais' },
  { lang: 'es-ES', name: 'Española' },
];

export default {};
