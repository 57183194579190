import auth from './auth';
import alert from './alert';
import stats from './stats';
import payouts from './payouts';
import orgs from './orgs';
import card from './card';
import business from './business';
import enterprises from './enterprises';
import memberships from './memberships';
import franchises from './franchises';
import coupons from './coupon';
import email from './email';
import registration from './registrations';

export default {
  auth: auth.reducer,
  stats: stats.reducer,
  alert: alert.reducer,
  payouts: payouts.reducer,
  orgs: orgs.reducer,
  card: card.reducer,
  business: business.reducer,
  enterprises: enterprises.reducer,
  memberships: memberships.reducer,
  franchises: franchises.reducer,
  coupons: coupons.reducer,
  email: email.reducer,
  registration: registration.reducer,
};
