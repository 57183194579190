/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import api from '../../utils/apiFetch';

// const path = 'catalog';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getFranchisesList: async (params) => {
    const res = await api.get(`franchises?${serialize(params)}`);
    return res;
  },
  getSecondaryMerchants: async (params) => {
    const res = await api.get(`franchises/business?${serialize(params)}`);
    return res;
  },
  getSecondaryMembershipPlans: async (params) => {
    const res = await api.get(
      `franchises/memberships/plans?${serialize(params)}`
    );
    return res;
  },
  getSecondaryMembershipPlanDiscounts: async (params) => {
    const res = await api.get(
      `franchises/memberships/discounts?${serialize(params)}`
    );
    return res;
  },
  getSecondaryMemberships: async (params) => {
    const res = await api.get(
      `franchises/memberships/members?${serialize(params)}`
    );
    return res;
  },
  syncMembershipPlan: async (payload) => {
    const res = await api.post(`franchises/memberships/plans`, payload);
    return res;
  },
  syncMembershipPlanDiscount: async (payload) => {
    const res = await api.post(`franchises/memberships/discounts`, payload);
    return res;
  },
  syncMembership: async (payload) => {
    const res = await api.post(`franchises/memberships/members`, payload);
    return res;
  },
  createFranchise: async (payload) => {
    const res = await api.post(`franchises`, payload);
    return res;
  },
  getFranchise: async (payload) => {
    const res = await api.get(`franchises/details?${serialize(payload)}`);
    return res;
  },
  getGiftCardsList: async (payload) => {
    const res = await api.get(`gift-cards/list?${serialize(payload)}`);
    return res;
  },
  createSecondaryMerchant: async (payload) => {
    const res = await api.post(`franchises/business`, payload);
    return res;
  },
  updateFranchise: async (payload) => {
    const res = await api.put(`franchises`, payload);
    return res;
  },
  issueFranchiseGiftCard: (payload) => api.post(`franchises/issue`, payload),
};
