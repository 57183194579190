/* eslint-disable react/destructuring-assignment,no-param-reassign */
import React, { useEffect } from 'react';
import { Box, Grid, Typography, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { TransactionTable } from '../../custom';
import { registration } from '../../../state';

const rowKeys = [
  'createdAt',
  'businessName',
  'firstName',
  'lastName',
  'email',
  'phone',
];
const InCompletedRegistrations = () => {
  const dispatch = useDispatch();

  const { total, data } = useSelector(
    registration.selectors.selectInCompleteRegistration
  );

  const isLoading = useSelector((state) =>
    registration.selectors.selectIsLoading(state)
  );

  const [params, setParams] = React.useState({ limit: 10, page: 1 });

  const colsTitles = [
    'Registration DateTime',
    'Business Name',
    'First Name',
    'Last Name',
    'Email Address',
    'Phone #',
  ];

  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD YYYY, HH:mm:ss'),
  };

  useEffect(() => {
    dispatch(registration.actions.getRegistration(params));
  }, [params]);
  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  return (
    <>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Typography variant="h5">
              List of merchants with unverified email address
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
          pagination={pagination}
          noDataMsg="Hmm... Looks like there is no data."
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};

export default InCompletedRegistrations;
