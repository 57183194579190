import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'loyalties';

const getLoyalties = createAsyncThunk(
  'loyalties/getLoyalties',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getLoyalties(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const syncReference = createAsyncThunk(
  'loyalties/syncReference',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.syncReference(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getCustomer = createAsyncThunk(
  'loyalties/getCustomer',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getCustomer(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const importSecondaryLoyalty = createAsyncThunk(
  'loyalties/importSecondaryLoyalty',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.importSecondaryLoyalty(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const importLoyalty = createAsyncThunk(
  'loyalties/importLoyalty',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.importLoyalty(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const updateLoyalty = createAsyncThunk(
  'loyalties/updateLoyalty',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.updateLoyalty(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      business: {},
    },
    reducers: {
      getBusiness: (state, action) => ({
        ...state,
        business: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getLoyalties,
    importSecondaryLoyalty,
    syncReference,
    getCustomer,
    importLoyalty,
    updateLoyalty,
  },
  selectors,
  reducer,
  name,
};
