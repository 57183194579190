import React from 'react';
import { Router, navigate, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Profile,
  Login,
  EditMerchants,
  MerchantsReports,
  PhysicalGiftCard,
  GiftCardsDashboard,
  MemberShips,
  StoreCredits,
  GiftCardImages,
} from '../components/routes';
import PrivateRoute from '../components/PrivateRoute';
import { SidebarHeader, Content } from '../components/layouts';
import { auth, stats } from '../state';
import { PreLoader } from '../components/custom';
import MerchantCode from '../components/routes/Merchants/MerchantCode';
import MerchantsList from '../components/routes/Merchants/MerchantsList';
// import CompanyProfile from '../components/routes/Enterprises/CompanyProfile';
import Enterprises from '../components/routes/Enterprises';
import MapMerchants from '../components/routes/Merchants/MapMerchants';
import { PosDevices } from '../components/routes/PosDevices';
import SyncMembers from '../components/routes/SyncMembers/SyncMembers';
import MerchantsDashboard from '../components/routes/Dashboard/MerchantsDashboard';
import ImportMembers from '../components/routes/SyncMembers/ImportMembers';
import Misc from '../components/routes/Misc/Misc';
import ImportLoyalty from '../components/routes/Loyalty/ImportLoyalty';
import AddPoints from '../components/routes/Loyalty/AddPoints';
import EmailTemplates from '../components/routes/EmailTemplates/EmailTemplatesRoutes';
import ImCompletedRoutes from '../components/routes/ImCompletedRoutes/ImCompletedRoutes';

const Dashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) =>
    auth.selectors.selectIsLoggedIn(state)
  );

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (!isLoggedIn) {
      const response = dispatch(auth.actions.refreshToken());
      response.then(() => {
        const date = new Date();
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = date;
        dispatch(
          stats.actions.getGiftCardStats({
            startDate,
            endDate,
            page: 1,
            limit: 5,
          })
        );
        setIsLoading(false);
      });
    }
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }

  if (isLoggedIn && location.pathname === '/dashboard/login') {
    navigate('/dashboard/merchants');
    return null;
  }

  return (
    <>
      {isLoggedIn ? <SidebarHeader /> : null}
      <Content withSidebar={isLoggedIn} md={12}>
        <Router basepath="/dashboard">
          <PrivateRoute path="/" redirect="/merchants" />
          <PrivateRoute path="/gift-cards" component={GiftCardsDashboard} />
          <PrivateRoute path="/store-credits" component={StoreCredits} />
          <PrivateRoute path="/memberships" component={MemberShips} />
          <PrivateRoute path="/merchants" component={MerchantsDashboard} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/edit-merchants" component={EditMerchants} />
          <PrivateRoute path="/merchants/list" component={MerchantsList} />
          <PrivateRoute
            path="/reports-merchants"
            component={MerchantsReports}
          />
          <PrivateRoute
            path="/physical-gift-cards"
            component={PhysicalGiftCard}
          />
          <PrivateRoute path="/gift-card-images" component={GiftCardImages} />
          <PrivateRoute path="/merchant-code" component={MerchantCode} />
          <PrivateRoute path="/map-merchants/*" component={MapMerchants} />
          <PrivateRoute path="/syncMembers/*" component={SyncMembers} />
          <PrivateRoute path="/import/loyalty" component={ImportLoyalty} />
          <PrivateRoute path="/loyalty/add-points" component={AddPoints} />
          <PrivateRoute
            path="/importMembers/square"
            component={ImportMembers}
          />
          <PrivateRoute path="/enterprises/*" component={Enterprises} />
          <PrivateRoute path="/pos-devices/*" component={PosDevices} />
          <PrivateRoute path="/email-templates/*" component={EmailTemplates} />
          <PrivateRoute
            path="/incomplete-registrations/*"
            component={ImCompletedRoutes}
          />
          <PrivateRoute path="/misc/*" component={Misc} />
          <Login path="/login" />
        </Router>
      </Content>
    </>
  );
};

export default Dashboard;
