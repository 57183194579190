/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import api from '../../utils/apiFetch';

// const path = 'catalog';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getLoyalties: (params) => api.get(`loyalties?${serialize(params)}`),
  syncReference: (id) => api.post(`loyalties/sync-reference/${id}`),
  getCustomer: (params) => api.get(`merchants/customer?${serialize(params)}`),
  importSecondaryLoyalty: (payload) =>
    api.post(`loyalties/import-secondary`, payload),
  importLoyalty: (payload) => api.post(`loyalties/import`, payload),
  updateLoyalty: (payload) => api.post(`loyalties/update`, payload),
};
