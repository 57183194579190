/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'registration';

const getRegistration = createAsyncThunk(
  'registration/getRegistration',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.getRegistration(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      inCompleteRegistration: { data: [], total: 0 },
    },
    extraReducers: {
      [getRegistration.pending]: (state) => {
        state.isLoading = true;
      },
      [getRegistration.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.inCompleteRegistration = action.payload;
      },
      [getRegistration.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    },
  }),
};

const selectors = {
  selectInCompleteRegistration: (state) =>
    getOr(
      {
        data: [],
        total: 0,
      },
      'inCompleteRegistration',
      state[name]
    ),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getRegistration,
  },
  selectors,
  reducer,
  name,
};
