/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';

const name = 'email';

const postEmailTemplate = createAsyncThunk(
  'email/postEmailTemplate',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.postEmailTemplate(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getEmailTemplates = createAsyncThunk(
  'email/getEmailTemplates',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.getEmailTemplates(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      emailTemplatesList: { data: [], total: 0 },
    },
    reducers: {
      setIsLoggedIn: (state, action) => ({
        ...state,
        isLoggedIn: action.payload,
      }),
      setUser: (state, action) => ({
        ...state,
        user: action.payload,
      }),
    },
    extraReducers: {
      [postEmailTemplate.pending]: (state) => {
        state.isLoading = true;
      },
      [postEmailTemplate.fulfilled]: (state) => {
        state.isLoading = false;
      },
      [postEmailTemplate.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      },
      [getEmailTemplates.pending]: (state) => {
        state.isLoading = true;
      },
      [getEmailTemplates.fulfilled]: (state, action) => {
        state.isLoading = false;
        console.log(action, 222);
        state.emailTemplatesList = action.payload;
      },
      [getEmailTemplates.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    },
  }),
};

const selectors = {
  selectEmailTemplates: (state) =>
    state[name].emailTemplatesList || { data: [], total: 0 },
};

export default {
  actions: {
    ...actions,
    postEmailTemplate,
    getEmailTemplates,
  },
  selectors,
  reducer,
  name,
};
