/* eslint-disable react/destructuring-assignment,no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Divider,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { TransactionTable } from '../../custom';
import { LANGUAGES } from '../../../utils/constants';
import EditEmailTemplateForm from './EditEmailTemplateForm';
import { email } from '../../../state';
import searchIcon from '../../../images/search.png';
import ViewEdit from '../../../images/viewEdit.png';
import { TEMPLATE_TEXTS } from './EmailTemplateConstants';

const rowKeys = ['appName', 'type', 'description', 'culture', 'id'];

const EmailTemplatesList = () => {
  const dispatch = useDispatch();
  const [template, setTemplate] = useState(null);

  const { data, total } = useSelector(email.selectors.selectEmailTemplates);
  const [params, setParams] = React.useState({ limit: 10, page: 1 });

  const colsTitles = [
    'App Name',
    'Template Name',
    'Description',
    'Language',
    '',
  ];

  const mods = {
    id: (id, row) => (
      <Grid container alignItems="center" direction="row">
        <Tooltip title="Edit" placement="top" arrow>
          <IconButton onClick={() => setTemplate(row)} cursor="pointer">
            <img
              src={ViewEdit}
              alt="pause-icon"
              style={{ width: 25, height: 25 }}
            />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Edit" placement="top" arrow>
          <div>
            <EmailTemplateMenu
              email={row}
              editTemplate={() => console.log(id, row.email)}
              editEmailTemplateDes={() => setTemplate(row)}
            />
          </div>
        </Tooltip> */}
      </Grid>
    ),
    type: (val, row) => row.name || TEMPLATE_TEXTS[val]?.name,
    appName: (val, row) => TEMPLATE_TEXTS[row.type]?.app,
    description: (val, row) => val || TEMPLATE_TEXTS[row.type]?.description,
    culture: (val) => LANGUAGES.find((l) => l.lang === val)?.name,
  };

  const [filteredData, setFilteredData] = useState([]);
  const updateFilteredData = () => {
    if (data) {
      const newFilteredData = data
        .filter((row) => {
          if (!params.keyword) return true;
          const matchesAppName = TEMPLATE_TEXTS[row.type].app
            .toLowerCase()
            .includes(params.keyword.toLowerCase());
          const matchesLanguage = LANGUAGES.find((l) => l.lang === row.culture)
            ?.name.toLowerCase()
            .includes(params.keyword.toLowerCase());
          const matchesTemplateName = TEMPLATE_TEXTS[row.type].name
            .toLowerCase()
            .includes(params.keyword.toLowerCase());

          return matchesAppName || matchesLanguage || matchesTemplateName;
        })
        .slice((params.page - 1) * params.limit, params.page * params.limit);

      setFilteredData(newFilteredData);
    }
  };
  useEffect(() => {
    updateFilteredData();
  }, [data, JSON.stringify(params), LANGUAGES]);
  const searchBox = () => (
    <Grid item>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item style={{ minWidth: 350 }}>
          <TextField
            placeholder="Search"
            value={params.keyword}
            fullWidth
            type="string"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            name="keyword"
            onChange={(val) => {
              setParams({ ...params, keyword: val.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => updateFilteredData()}
                    style={{ marginRight: -14 }}
                  >
                    <img
                      src={searchIcon}
                      alt="Search"
                      style={{
                        width: 25,
                        height: 25,
                        paddingTop: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    dispatch(email.actions.getEmailTemplates());
  }, []);
  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  return (
    <>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Typography variant="h5">Default Email Templates</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          mods={mods}
          searchBox={searchBox}
          data={filteredData}
          pagination={pagination}
          noDataMsg="Hmm... Looks like there is no data."
        />
      </Box>
      {template && (
        <EditEmailTemplateForm
          template={template}
          closeModal={() => {
            setTemplate(null);
            dispatch(email.actions.getEmailTemplates());
          }}
        />
      )}
    </>
  );
};

export default EmailTemplatesList;
