import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'franchises';

const getFranchisesList = createAsyncThunk(
  'franchises/getFranchisesList',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getFranchisesList(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getSecondaryMerchants = createAsyncThunk(
  'franchises/getSecondaryMerchants',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getSecondaryMerchants(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSecondaryMembershipPlans = createAsyncThunk(
  'franchises/getSecondaryMembershipPlans',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getSecondaryMembershipPlans(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSecondaryMembershipPlanDiscounts = createAsyncThunk(
  'franchises/getSecondaryMembershipPlanDiscounts',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getSecondaryMembershipPlanDiscounts(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getSecondaryMemberships = createAsyncThunk(
  'franchises/getSecondaryMemberships',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getSecondaryMemberships(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const syncMembershipPlan = createAsyncThunk(
  'franchises/syncMembershipPlan',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.syncMembershipPlan(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const syncMembershipPlanDiscount = createAsyncThunk(
  'franchises/syncMembershipPlanDiscount',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.syncMembershipPlanDiscount(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const syncMembership = createAsyncThunk(
  'franchises/syncMembership',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.syncMembership(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createFranchise = createAsyncThunk(
  'franchises/createFranchise',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.createFranchise(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getFranchise = createAsyncThunk(
  'franchises/getFranchise',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getFranchise(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateFranchise = createAsyncThunk(
  'franchises/updateFranchise',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.updateFranchise(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getGiftCardsList = createAsyncThunk(
  'franchises/getGiftCardsList',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getGiftCardsList(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const issueFranchiseGiftCard = createAsyncThunk(
  'giftCards/issueShopifyGiftCard',
  async (payload, { rejectWithValue }) => {
    try {
      return api.issueFranchiseGiftCard(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createSecondaryMerchant = createAsyncThunk(
  'franchises/createSecondaryMerchant',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.createSecondaryMerchant(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      franchisesList: {
        data: [],
        total: 0,
      },
      secondaryMerchantsList: {
        data: [],
        total: 0,
      },
      franchiseBusiness: {
        id: '21312312312',
        business: { id: '' },
        franchise: { id: '' },
      },
    },
    reducers: {
      getBusiness: (state, action) => ({
        ...state,
        business: action.payload,
      }),
      setFranchiseBusiness: (state, action) => ({
        ...state,
        franchiseBusiness: action.payload,
      }),
    },
    extraReducers: {
      [getFranchisesList.fulfilled]: (state, action) => {
        return {
          ...state,
          franchisesList: action.payload,
        };
      },
      [getSecondaryMerchants.fulfilled]: (state, action) => {
        return {
          ...state,
          secondaryMerchantsList: action.payload,
        };
      },
      [createFranchise.fulfilled]: (state, action) => {
        return {
          ...state,
          posDeviceList: action.payload,
        };
      },
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectFranchiseBusiness: (state) =>
    getOr({}, 'franchiseBusiness', state[name]),
  selectFranchisesList: (state) =>
    getOr({ data: [], total: 0 }, 'franchisesList', state[name]),
  selectSecondaryMerchant: (state) =>
    getOr({ data: [], total: 0 }, 'secondaryMerchantsList', state[name]),
};

export default {
  actions: {
    ...actions,
    getFranchisesList,
    getSecondaryMerchants,
    getSecondaryMembershipPlans,
    getSecondaryMembershipPlanDiscounts,
    getSecondaryMemberships,
    syncMembershipPlan,
    syncMembershipPlanDiscount,
    syncMembership,
    createFranchise,
    getFranchise,
    getGiftCardsList,
    issueFranchiseGiftCard,
    createSecondaryMerchant,
    updateFranchise,
  },
  selectors,
  reducer,
  name,
};
