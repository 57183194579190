const TEMPLATE_TEXTS = {
  GiftCardOrderConfirmation: {
    app: 'Gift Cards',
    name: 'Gift Card Order Confirmation',
    description: 'Email sent to the buyer when a gift card is purchased',
  },
  GiftCardAddFundOrder: {
    app: 'Gift Cards',
    name: 'Gift Card Add Fund Order',
    description: 'Email sent to the buyer when funds are added to a gift card',
  },
  GiftCardGifterConfirmation: {
    app: 'Gift Cards',
    name: 'Gift Card Gifter Confirmation',
    description: 'Email sent to the buyer when a gift card is purchased',
  },
  GiftCardGifteeConfirmation: {
    app: 'Gift Cards',
    name: 'Gift Card Giftee Confirmation',
    description: 'Email sent to the recipient when a gift card is purchased',
  },
  GiftCardRefund: {
    app: 'Gift Cards',
    name: 'Gift Card Refund',
    description: 'Email sent to the buyer when a gift card is refunded',
  },
  MembershipConfirmation: {
    app: 'Memberships',
    name: 'Membership Confirmation',
    description: 'Email sent to the buyer when a membership is purchased',
  },
  MembershipPaid: {
    app: 'Memberships',
    name: 'Membership Paid',
    description: 'Email sent to the buyer when a membership is paid',
  },
  MembershipCustomerPaymentFailed: {
    app: 'Memberships',
    name: 'Membership Customer Payment Failed',
    description: 'Email sent to the buyer when a membership payment fails',
  },
  MembershipPaymentFailedForMerchant: {
    app: 'Memberships',
    name: 'Membership Payment Failed For Merchant',
    description: 'Email sent to the merchant when a membership payment fails',
  },
  MembershipCardAdded: {
    app: 'Memberships',
    name: 'Membership Card Added',
    description: 'Email sent to the buyer when a membership card is added',
  },
  MembershipRenew: {
    app: 'Memberships',
    name: 'Membership Renewal Fee Payment',
    description: 'Email sent to the buyer when a membership is renewed',
  },
  MembershipExpireNotification: {
    app: 'Memberships',
    name: 'Membership Expire Notification',
    description: 'Email sent to the buyer when a membership is about to expire',
  },
  MembershipWithoutCardExpireNotification: {
    app: 'Memberships',
    name: 'Membership Without Card Expire Notification',
    description:
      'Email sent to the buyer when a membership without a card is about to expire',
  },
  MembershipPauseConfirm: {
    app: 'Memberships',
    name: 'Membership Pause Confirm',
    description: 'Email sent to the buyer when a membership is paused',
  },
  MembershipPauseScheduled: {
    app: 'Memberships',
    name: 'Membership Pause Scheduled',
    description:
      'Email sent to the buyer when a membership is scheduled to be paused',
  },
  MembershipResumeConfirm: {
    app: 'Memberships',
    name: 'Membership Resume Confirm',
    description: 'Email sent to the buyer when a membership is resumed',
  },
  MembershipTrial: {
    app: 'Memberships',
    name: 'Membership Trial',
    description: 'Email sent to the buyer when a membership trial is started',
  },
  MembershipGifter: {
    app: 'Memberships',
    name: 'Membership Gifter',
    description: 'Email sent to the buyer when a membership is gifted',
  },
  MembershipGiftee: {
    app: 'Memberships',
    name: 'Membership Giftee',
    description: 'Email sent to the recipient when a membership is gifted',
  },
  LoyaltyConfirmation: {
    app: 'Loyalty',
    name: 'Loyalty Confirmation',
    description: 'Email sent to the buyer when a loyalty program is purchased',
  },
  LoyaltyRewardEarned: {
    app: 'Loyalty',
    name: 'Loyalty Reward Earned',
    description: 'Email sent to the buyer when a loyalty reward is earned',
  },
  CouponCodeConfirmation: {
    app: 'Coupons',
    name: 'Coupon Code Confirmation',
    description: 'Email sent to the buyer when a coupon code is purchased',
  },
  StoreCreditConfirmation: {
    app: 'Store Credits',
    name: 'Store Credit Confirmation',
    description: 'Email sent to the buyer when store credit is purchased',
  },
  StoreCreditAddFundConfirmation: {
    app: 'Store Credits',
    name: 'Store Credit Add Fund Confirmation',
    description: 'Email sent to the buyer when funds are added to store credit',
  },
  TicketOrderConfirmation: {
    app: 'Tickets',
    name: 'Ticket Order Confirmation',
    description: 'Email sent to the buyer when a ticket is purchased',
  },
  EnterpriseOrderGifterConfirmation: {
    app: 'Gift Cards',
    name: 'Enterprise Order Gifter Confirmation',
    description:
      'Email sent to the buyer when an enterprise order is purchased',
  },
};
// eslint-disable-next-line import/prefer-default-export
export { TEMPLATE_TEXTS };
