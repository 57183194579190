import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from '../../PrivateRoute';
import InCompletedRegistrations from './InCompletedRegistrations';

const ImCompletedRoutes = () => {
  return (
    <Router basepath="dashboard/incomplete-registrations">
      <PrivateRoute path="/" component={InCompletedRegistrations} />
    </Router>
  );
};

export default ImCompletedRoutes;
